import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { Button } from '@/design-system/atoms/Button';
import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { IconAlertTriangle } from '@/design-system/atoms/Icons/IconAlertTriangle';
import { IconInfo } from '@/design-system/atoms/Icons/IconInfo';
import { RichTextEditor } from '@/design-system/atoms/RichTextEditor';

// import Typography from '@/design-system/atoms/Typography';
import styles from './HomepageAlert.module.scss';

const HomepageAlert = ({ closable = true, content, ...props }) => {
    const { alertType } = props;
    const alertId = props.alertId || props.id;
    const [isOpen, setIsOpen] = useSessionStorage(`renderAlert[${alertId}]`, true, {
        initializeWithValue: false,
    });

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        isOpen && (
            <div
                className={classNames(
                    styles['homepage-alert'],
                    alertType ? styles[alertType] : null,
                )}
            >
                <ContentContainer>
                    <div className={styles['homepage-alert__content']} {...props}>
                        {alertType === 'warning' ? (
                            <IconAlertTriangle
                                aria-labelledby="homepage-alert-content"
                                title="icon-alert"
                                className={styles['homepage-alert__icon']}
                                size="small"
                            />
                        ) : (
                            <IconInfo
                                aria-labelledby="homepage-alert-content"
                                title="icon-alert"
                                className={styles['homepage-alert__icon']}
                                size="small"
                            />
                        )}
                        <RichTextEditor
                            text={content}
                            className={styles[`homepage-alert__inner-content`]}
                            id="homepage-alert-content"
                            analytics={{
                                componentName: 'alert_banner',
                                componentTitle: 'alert_banner',
                            }}
                        />
                        {closable && (
                            <Button
                                ariaLabel={'Close Alert'}
                                buttonStyle={Button.STYLE.TERTIARY}
                                iconName="x"
                                onClick={handleClose}
                            />
                        )}
                    </div>
                </ContentContainer>
            </div>
        )
    );
};

HomepageAlert.propTypes = {
    content: PropTypes.string,
    closable: PropTypes.bool,
};

export default HomepageAlert;
